<template>
  <tr class="heighRow">
    <td>
      <div class="d-flex justify-content-center">
        <span v-text="item.count" class="text-right"></span>
      </div>
    </td>
    <td class="name">
      <input
        v-if="edit"
        v-model="itemChoice.name"
        placeholder="Nhập tên"
        class="w-100 h-90 input"
      />
      <span class="font-weight-bolder" v-else>{{ item.name }}</span>
    </td>
    <td class="value">
      <input
        v-if="edit"
        v-model="itemChoice.value"
        placeholder="Nhập giá trị"
        class="w-100 h-90 input"
      />
      <span v-else>{{ item.value }}</span>
    </td>
    <td class="code">
      <input
        v-if="edit"
        v-model="itemChoice.code"
        placeholder="Nhập mã"
        class="w-100 h-90 input"
      />
      <span v-else>{{ item.code }}</span>
    </td>
    <td v-show="checkPermission('PROPERTY_UPDATE')">
      <template v-if="edit">
        <div class="d-flex justify-content-center">
          <v-icon class="mr-2 text-primary" small @click="updateItem()"
            >mdi-check</v-icon
          >
          <v-icon class="mr-2 text-danger" small @click="cancelEdit()"
            >mdi-close</v-icon
          >
        </div>
      </template>

      <template v-else>
        <div class="d-flex justify-content-center">
          <v-icon
            small
            class="mr-2 text-primary"
            @click="editItem()"
            v-b-tooltip
            title="Cập nhật"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            class="mr-2 text-danger"
            @click="showDeleteAlert"
            v-b-tooltip
            title="Xóa"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </td>
  </tr>
</template>

<style>
.heighRow {
  height: 40px;
}
.textWidth {
  width: 70%;
}
.input {
  border: 1px solid #ecf0f3;
  border-radius: 5px;
  padding-left: 5px;
}
th.id {
  width: 10%;
}
th.code {
  width: 20%;
}
th.name,
th.value {
  width: 30%; /* Not necessary, since only 70% width remains */
}
</style>

<script>
import Swal from 'sweetalert2';

export default {
  props: ['item', 'listFeature'],
  data() {
    return {
      edit: false,
      itemChoice: {
        id: 0,
        name: '',
        value: '',
        code: '',
      },
    };
  },
  updated() {
    this.checkAddRow();
  },
  methods: {
    editItem() {
      this.edit = true;
      this.itemChoice.id = this.item.id;
      this.itemChoice.name = this.item.name;
      this.itemChoice.value = this.item.value;
      this.itemChoice.code = this.item.code;
    },
    cancelEdit: function () {
      this.edit = false;
      if (this.item.name == '') {
        this.$emit('cancelAdd');
      }
      this.itemChoice.id = 0;
      this.itemChoice.name = '';
    },
    updateItem: function () {
      this.edit = false;
      if (this.itemChoice.name === '') {
        alert('Vui lòng nhập tên giá trị!');
        return;
      }
      if (!this.isValidData(this.itemChoice.name)) {
        alert('Tên giá trị không được chứa ký tự đặc biệt!');
        return;
      }
      if (this.itemChoice.code === '') {
        alert('Vui lòng nhập mã giá trị!');
        return;
      }
      if (!this.isValidData(this.itemChoice.code)) {
        alert('Mã giá trị không được chứa ký tự đặc biệt!');
        return;
      }
      if (this.itemChoice.value === '') {
        alert('Vui lòng nhập giá trị!');
        return;
      }
      if (!this.isValidData(this.itemChoice.value)) {
        alert('Giá trị không được chứa ký tự đặc biệt!');
        return;
      }
      if (this.item.name == '') {
        this.itemChoice.id = this.item.id;
        this.item.name = this.itemChoice.name;
        this.item.value = this.itemChoice.value;
        this.item.code = this.itemChoice.code;
        this.$emit('addItem', this.itemChoice);
      } else {
        this.$emit('updateItem', this.itemChoice);
      }
      this.itemChoice.code = '';
      this.itemChoice.name = '';
      this.itemChoice.value = '';
    },
    showDeleteAlert: function () {
      Swal.fire({
        title: 'Xóa giá trị thuộc tính!',
        text: 'Bạn có chắc muốn xóa giá trị thuộc tính này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.$emit('deleteItem', this.item.id);
        }
      });
    },
    checkPermission(condition) {
      if (this.listFeature.indexOf(condition) > -1) {
        return true;
      } else {
        return false;
      }
    },
    checkAddRow() {
      if (this.item.name == '') {
        this.edit = true;
      } else if (this.edit) {
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    isValidData(data) {
      var format = /[`!@#$%^&*()+\-={};':"|,.<>?~]/;
      return !format.test(data);
    },
  },
};
</script>
